import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import secureLocalStorage from 'react-secure-storage';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: 'AIzaSyBF9YikFck1Vey2FsxJlkzogKi52x4DPnM',
  authDomain: 'adrena-391314.firebaseapp.com',
  projectId: 'adrena-391314',
  storageBucket: 'adrena-391314.appspot.com',
  messagingSenderId: '457960404710',
  appId: '1:457960404710:web:6b125fd7f3960ccdec2d27',
  measurementId: 'G-0WLN89RG1J'
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const messaging = getMessaging();

const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const requestForToken = async () => {
  try {
    console.log('requestForToke');
    const currentToken = await getToken(messaging, {
      vapidKey: `BNVHSp_6UHAdO0xic6S8n2vv-fPEjd54qlSxL15ty3J5W6h0rDhCVUPRBRtv8YeEhpBRrecjzty1doukYr8cDnk`
    });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Perform any other neccessary action with the token
      secureLocalStorage.setItem('deviceToken', currentToken);
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('payload', messaging);
      resolve(payload);
    });
  });
