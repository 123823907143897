import { createSlice } from '@reduxjs/toolkit';
import { encrypt } from 'constant';
import { toast } from 'react-toastify';
import api from 'services';
import ADRENAAPI from 'services/apiConstant';

// Slice

const slice = createSlice({
  name: 'paymentSlice',
  initialState: {
    plansListData: null,
    creditCardList: null,
    serviceList: null,
    paymentCalculationData: null,
    sumSubStatus: null,
    userStoreDetail: null,
    myStoreInfo: null,
    storeProductList: null,
    isCancelledPlanExist: null,
    paymentHistoryTotalCount: null,
    paymentHistoryData: null,
    billingHistoryData: [],
    lastPaymentData: null
  },
  reducers: {
    getPlansListSuccess: (state, action) => {
      state.plansListData = action.payload;
    },
    getCreditCardListSuccess: (state, action) => {
      state.creditCardList = action.payload;
    },
    removeCreditCardSuccess: (state, action) => {
      state.creditCardList = state.creditCardList.filter((item) => item.id !== action.payload.id);
    },
    getServicesListSuccess: (state, action) => {
      state.serviceList = action.payload;
    },
    paymentCalculationSuccess: (state, action) => {
      state.paymentCalculationData = action.payload;
    },
    getSumsubStatusSuccess: (state, action) => {
      state.sumSubStatus = action.payload;
    },
    updateSumsubStatusSuccess: (state, action) => {
      state.sumSubStatus = action.payload;
    },
    getUserStoreDetailSuccess: (state, action) => {
      state.userStoreDetail = action.payload;
    },
    getMyStoreDetailSuccess: (state, action) => {
      state.myStoreInfo = action.payload;
    },
    updateStoreInfoSuccess: (state, action) => {
      state.myStoreInfo = action.payload;
    },
    getSellerStoreProductsSuccess: (state, action) => {
      state.storeProductList = action.payload;
    },
    checkCancelPlanValiditySuccess: (state, action) => {
      state.isCancelledPlanExist = action.payload.isCancelledPlanExist;
    },
    getPaymentHistorySuccess: (state, action) => {
      state.paymentHistoryData = action.payload.data;
      state.paymentHistoryTotalCount = action.payload.totalCount;
    },
    myStoreFavouriteAdsSuccess: (state, action) => {
      let objId = action.payload.id;
      let objIndex = state.storeProductList['data'].findIndex((item) => item.id === objId);
      state.storeProductList['data'][objIndex] = action.payload;
    },
    myStoreTopFavouriteAdsSuccess: (state, action) => {
      let objId = action.payload.id;
      let objIndex = state.myStoreInfo['topThreeAds'].findIndex((item) => item.id === objId);
      state.myStoreInfo['topThreeAds'][objIndex] = action.payload;
    },
    billingHistorySuccess: (state, action) => {
      state.billingHistoryData = action.payload;
    },
    getBillingHistorySuccess: (state, action) => {
      state.lastPaymentData = action.payload;
    }
  }
});
const {
  getPlansListSuccess,
  getCreditCardListSuccess,
  removeCreditCardSuccess,
  getServicesListSuccess,
  paymentCalculationSuccess,
  getSumsubStatusSuccess,
  updateSumsubStatusSuccess,
  getUserStoreDetailSuccess,
  getMyStoreDetailSuccess,
  updateStoreInfoSuccess,
  getSellerStoreProductsSuccess,
  checkCancelPlanValiditySuccess,
  getPaymentHistorySuccess,
  myStoreFavouriteAdsSuccess,
  myStoreTopFavouriteAdsSuccess,
  billingHistorySuccess,
  getBillingHistorySuccess
} = slice.actions;

export const getPlansList = (data, setLoader) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getPlansList}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getPlansListSuccess(result.data));
        setLoader(false);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const addCreditCard = (data, setIsloading, setStepperId) => async () => {
  api
    .post(`${ADRENAAPI.addCreditCard}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        if (setIsloading) {
          setIsloading(false);
          setStepperId(0);
          toast.success(response.data.message);
        }
        // dispatch(getPlansListSuccess(result.data));
      } else {
        if (setIsloading) {
          setIsloading(false);
          toast.warning(response.data.message);
        }
      }
    })
    .catch(() => {});
};
export const getCreditCardList = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getCreditCardList}`, {
      language: data
    })
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getCreditCardListSuccess(result.data));
      }
      //  else {
      //   toast.warning(response.data.message);
      // }
    })
    .catch(() => {});
};
export const removeCreditCard = (data, setShowDialog) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.removeCreditCard}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(removeCreditCardSuccess(result.data));
        setShowDialog(false);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const getServicesList = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.getServicesList, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getServicesListSuccess(result.data));
        if (setIsLoading) {
          setIsLoading(false);
        }
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPaymentCalculation = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.getPaymentCalculation, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(paymentCalculationSuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getSumsubStatus = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.getSumsubStatus, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getSumsubStatusSuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const updateSumsubStatus = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.updateSumsubStatus, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(updateSumsubStatusSuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const makePayment =
  (
    requestParams,
    adFormData,
    setIsloading,
    addproduct,
    navigate,
    isCancelledPlanExist,
    paymentType
  ) =>
  async (dispatch) => {
    try {
      await api.post(ADRENAAPI.makePayment, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          if (result?.invoiceUrl) {
            window.sessionStorage.setItem('paymentData', JSON.stringify(result));
          } else {
            sessionStorage.clear();
          }

          if (adFormData) {
            let data = {
              productId: adFormData?.id,
              language: adFormData?.language,
              subscriptionType: adFormData?.userPlan?.subscriptionType,
              productStatus: 'publish',
              isCancelledPlanExist: isCancelledPlanExist ? isCancelledPlanExist : '',
              changePlanTo: adFormData?.userPlan?.upDownType,
              paymentCompleted: paymentType == 'credit_card' ? true : false
            };
            const newObj = { ...adFormData, ...data };
            dispatch(
              addproduct(
                newObj,
                navigate,
                `/sell-equipment/ad-ready/${encrypt(adFormData?.id)}`,
                '',
                setIsloading
              )
            );
          }
        } else {
          setIsloading(false);
          toast.warning(response.data.message);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
export const storeDownGradePayment =
  (requestParams, adFormData, setIsloading, addproduct, navigate,paymentType) => async (dispatch) => {
    try {
      await api.post(ADRENAAPI.storeDownGradePayment, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          if (result?.invoiceUrl) {
            window.sessionStorage.setItem('paymentData', JSON.stringify(result));
          } else {
            sessionStorage.clear();
          }

          if (adFormData) {
            let data = {
              productId: adFormData?.id,
              language: adFormData?.language,
              subscriptionType: adFormData?.userPlan?.subscriptionType,
              productStatus: 'publish',
              paymentCompleted: paymentType == 'credit_card' ? true : false
            };
            const newObj = { ...adFormData, ...data };
            dispatch(
              addproduct(
                newObj,
                navigate,
                `/sell-equipment/ad-ready/${encrypt(adFormData?.id)}`,
                '',
                setIsloading
              )
            );
          }
        } else {
          setIsloading(false);
          toast.warning(response.data.message);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
export const makeServicePayment = (requestParams, setIsloading, navigate) => async () => {
  try {
    await api.post(ADRENAAPI.makeServicePayment, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        navigate('/my-account/verify-badge/documentation');
        setIsloading(false);
      } else {
        toast.warning(response.data.message);
        setIsloading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getUserStoreDetail = () => async (dispatch) => {
  try {
    await api.get(ADRENAAPI.getUserStoreDetail).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getUserStoreDetailSuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getMyStoreDetail = (data, language) => async (dispatch) => {
  try {
    await api.post(`${ADRENAAPI.getMyStoreDetail}/${language}`, data).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getMyStoreDetailSuccess(result.data));
      }
      //  else {
      //   toast.warning(response.data.message);
      // }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const updateStoreInfo = (data) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.updateStoreInfo, data).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(updateStoreInfoSuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getSellerStoreProducts = (data, setLoader, setAnchorEl) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.getSellerStoreProducts, data).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getSellerStoreProductsSuccess(result));
        if (setLoader) {
          setLoader(false);
        }
        if (setAnchorEl) {
          setAnchorEl(null);
        }
      } else {
        toast.warning(response.data.message);
        if (setLoader) {
          setLoader(false);
        }
        if (setAnchorEl) {
          setAnchorEl(null);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const checkCancelPlanValidity = (data) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.checkCancelPlanValidity, data).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(checkCancelPlanValiditySuccess(result));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const purchaseProduct =
  (requestParams, setIsloading, setShowDialog, handleSignature) => async () => {
    try {
      await api.post(ADRENAAPI.purchaseProduct, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          setIsloading(false);
          setShowDialog(false);
          handleSignature();
        } else {
          toast.warning(response.data.message);
          setIsloading(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
export const purchaseProductInInstalment =
  (requestParams, setIsloading, setShowDialog, handleSignature) => async () => {
    try {
      await api.post(ADRENAAPI.purchaseProductInInstalment, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          setIsloading(false);
          setShowDialog(false);
          handleSignature();
        } else {
          toast.warning(response.data.message);
          setIsloading(false);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPaymentHistory = (requestParams, setLoadMore) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.getPaymentHistory, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getPaymentHistorySuccess(result));
        setLoadMore(false);
      } else {
        toast.warning(response.data.message);
        setLoadMore(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const myStoreFavouriteAds = (data, myStoreTop) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.productFavourite}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        if (myStoreTop) {
          dispatch(myStoreTopFavouriteAdsSuccess(result.data));
        } else {
          dispatch(myStoreFavouriteAdsSuccess(result.data));
        }
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};

export default slice.reducer;

export const billingHistory = (request) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.billingHistory, request).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(billingHistorySuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const sendInvoiceToMail = (request) => async () => {
  try {
    await api.post(ADRENAAPI.billingPdfEmail, request).then((response) => {
      let result = response.data;
      if (result?.status) {
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBillingHistory = (request) => async (dispatch) => {
  try {
    await api.post(ADRENAAPI.getBillingHistory, request).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(getBillingHistorySuccess(result));
      } else {
        // toast.warning(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
